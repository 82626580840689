body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slds-brand-band {
  margin-top: 60px;
}

/* Adjust margin for screens 768px and up */
@media screen and (min-width: 768px) {
  .slds-brand-band {
    margin-top: 100px;
  }
}

.kipzco-brand-mobile {
  font-size: 19px;
  width: 100%;
  text-align: center;
  margin-left: -35px;
}

.kipzco-brand-desktop {
  font-size: 26px;
  padding: 4.5px 0 0 .5rem;
  color: rgb(68, 68, 68);
}